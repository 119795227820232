const themeProperties = {
  fontSizes: {
    subTitleSize: '14px',
    titleSize: '18px',
  },
  primaryColor: '#68B030',
  light: {
    primaryFontColor: '#2f3336',
    dividerColor: '#d5d5d5',
    secondaryColor: '#ecb641',
    blueishGrey: '#f2f2f7',
    backgroundColor: '#fff',
    highlightBackground: '#4d5153',
    light: '#fff',
    dark: '#000',
  },
  dark: {
    primaryFontColor: '#ffffff',
    dividerColor: '#101113',
    secondaryColor: '#ecb641',
    blueishGrey: '#f2f2f7',
    backgroundColor: '#343a40',
    highlightBackground: '#f2f2f2',
    light: '#fff',
    dark: '#000',
  },
};

export default themeProperties;
