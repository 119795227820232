import React from "react";

function PaypalIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.999"
      height="20"
      viewBox="0 0 31.999 20"
    >
      <g data-name="Group 1466" transform="translate(-60)">
        <g data-name="Group 979" transform="translate(60)">
          <g data-name="Group 975">
            <rect
              width="31.999"
              height="20"
              fill="#1c3663"
              data-name="Rectangle 758"
              rx="4.18"
            ></rect>
            <path
              fill="#fff"
              d="M940.84 276a.446.446 0 00-.326.2s-.747 1.278-.817 1.4c-.04.067-.08.025-.087 0l-.232-1.4a.286.286 0 00-.284-.2h-.463a.154.154 0 00-.157.2s.354 2.015.423 2.49c.035.261 0 .306 0 .306l-.461.8c-.067.107-.032.2.08.2h.533a.436.436 0 00.326-.2l2.072-3.5s.2-.3.015-.3h-.625"
              data-name="Path 2517"
              transform="translate(-925.398 -267.146)"
            ></path>
            <path
              fill="#fff"
              d="M915 274.139a1.093 1.093 0 01-.657.172h-.127a.157.157 0 01-.159-.2l.157-.675a.266.266 0 01.249-.2h.169a.832.832 0 01.453.1.349.349 0 01.149.311.563.563 0 01-.234.5m1.123-1.041a.78.78 0 00-.286-.3 1.275 1.275 0 00-.451-.167 3.656 3.656 0 00-.615-.05h-1.086a.274.274 0 00-.249.2l-.73 3.183a.152.152 0 00.157.2h.52a.266.266 0 00.249-.2l.179-.772a.264.264 0 01.249-.2h.149a2.448 2.448 0 001.494-.391 1.205 1.205 0 00.53-1.031.952.952 0 00-.1-.468"
              data-name="Path 2518"
              transform="translate(-906.269 -264.618)"
            ></path>
            <path
              fill="#fff"
              d="M928.421 275.946a1.992 1.992 0 00-.929-.167 4.156 4.156 0 00-.63.05c-.154.025-.169.027-.266.05a.306.306 0 00-.229.249l-.065.252c-.035.167.06.159.1.147.085-.025.132-.05.309-.092a2.4 2.4 0 01.5-.065 1.265 1.265 0 01.471.067.249.249 0 01.157.237.142.142 0 010 .072.085.085 0 01-.075.047c-.3.017-.5.045-.767.082a2.35 2.35 0 00-.66.182 1.013 1.013 0 00-.448.354.954.954 0 00-.149.543.635.635 0 00.214.5.794.794 0 00.548.187 1.41 1.41 0 00.326-.03 1.5 1.5 0 00.249-.087 1.246 1.246 0 00.214-.117l.2-.132-.02.085a.154.154 0 00.139.194h.5a.276.276 0 00.224-.2l.379-1.639a.847.847 0 00.02-.134v-.125a.548.548 0 00-.312-.51zm-.921 1.954a1.578 1.578 0 01-.2.1.724.724 0 01-.271.06.6.6 0 01-.3-.057.2.2 0 01-.1-.194.386.386 0 01.077-.249.535.535 0 01.219-.149 2.029 2.029 0 01.344-.077c.12-.015.354-.042.383-.042s.05-.017.037.062c0 .035-.075.314-.1.441a.262.262 0 01-.089.105z"
              data-name="Path 2519"
              transform="translate(-915.974 -266.997)"
            ></path>
            <path
              fill="#fff"
              d="M966.326 275.956a1.993 1.993 0 00-.929-.167 4.156 4.156 0 00-.63.05c-.154.025-.172.027-.266.047a.306.306 0 00-.229.249l-.062.249c-.037.167.057.159.1.147a3.027 3.027 0 01.311-.092 2.243 2.243 0 01.5-.065 1.265 1.265 0 01.471.067.269.269 0 01.149.309.085.085 0 01-.075.047c-.3.017-.51.045-.769.082a2.4 2.4 0 00-.66.182 1.021 1.021 0 00-.446.354.953.953 0 00-.149.543.642.642 0 00.212.5.8.8 0 00.548.187 1.38 1.38 0 00.326-.03 1.6 1.6 0 00.249-.087 1.246 1.246 0 00.214-.117l.2-.132-.02.085a.154.154 0 00.139.194h.5a.276.276 0 00.224-.2l.379-1.639a.862.862 0 00.02-.134v-.125a.548.548 0 00-.307-.504zm-.921 1.952a1.434 1.434 0 01-.2.1.7.7 0 01-.271.06.586.586 0 01-.294-.057.2.2 0 01-.1-.194.4.4 0 01.075-.249.542.542 0 01.222-.149 1.973 1.973 0 01.344-.077c.12-.015.354-.042.384-.042s.05-.017.035.062c0 .035-.072.314-.1.441a.227.227 0 01-.082.1z"
              data-name="Path 2520"
              transform="translate(-944.18 -267.004)"
            ></path>
            <path
              fill="#fff"
              d="M952.915 274.181a1.116 1.116 0 01-.657.169h-.127a.154.154 0 01-.159-.2l.157-.675a.264.264 0 01.249-.2h.172a.844.844 0 01.451.1.341.341 0 01.152.309.575.575 0 01-.234.5m1.123-1.041a.8.8 0 00-.286-.306 1.289 1.289 0 00-.453-.167 3.625 3.625 0 00-.613-.047h-1.09a.271.271 0 00-.249.2l-.732 3.183a.154.154 0 00.157.2h.523a.274.274 0 00.249-.2l.177-.772a.269.269 0 01.249-.2h.149a2.443 2.443 0 001.494-.391 1.223 1.223 0 00.53-1.031 1.011 1.011 0 00-.1-.468"
              data-name="Path 2521"
              transform="translate(-934.48 -264.647)"
            ></path>
            <path
              fill="#fff"
              d="M977.358 272.69h-.468a.264.264 0 00-.249.182.1.1 0 000 .017.414.414 0 01-.025.117l-.643 2.911-.027.125a.154.154 0 00.137.192h.483a.269.269 0 00.249-.182.037.037 0 000-.017l.7-3.153a.157.157 0 00-.154-.192z"
              data-name="Path 2522"
              transform="translate(-953.365 -264.693)"
            ></path>
            <path
              fill="#fff"
              d="M983.5 273.272h-.1a.152.152 0 01-.117-.042.182.182 0 01-.042-.132v-.269h-.057v-.072h.057v-.147h.087v.147h.174v.072h-.174v.291a.214.214 0 000 .042.1.1 0 00.027.027.151.151 0 00.055 0 .127.127 0 00.042 0 .07.07 0 00.032 0z"
              data-name="Path 2523"
              transform="translate(-958.78 -264.669)"
            ></path>
            <path
              fill="#fff"
              d="M985.7 273.8h-.085v-.289a.529.529 0 000-.062.162.162 0 000-.047.057.057 0 00-.03-.03.124.124 0 00-.057 0 .162.162 0 00-.072.017.546.546 0 00-.07.045.094.094 0 000 .025v.351h-.086v-.351a.206.206 0 000-.05.062.062 0 00-.03-.027.124.124 0 00-.057 0 .144.144 0 00-.07.017.29.29 0 00-.07.042v.379h-.085v-.5h.085v.055a.386.386 0 01.08-.05.191.191 0 01.085-.02.169.169 0 01.087.022.134.134 0 01.052.06.406.406 0 01.095-.062.248.248 0 01.092-.02.147.147 0 01.122.05.224.224 0 01.04.142z"
              data-name="Path 2524"
              transform="translate(-960.12 -265.19)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PaypalIcon;
